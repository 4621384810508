import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layouts/courseLandingLayout"
import styled from "styled-components"
import PSModal from "../../components/modal"
import SyllabusForm from "../../components/forms/syllabusForm"
import Button from "../../components/buttons/linkButton"
import { Container, Col, Row } from "react-bootstrap"
import MattBig from "../../assets/images/matt-big.png"
import Mentor from "../../assets/icons/mentor.png"
import Monitor from "../../assets/icons/monitor.png"
import Work from "../../assets/icons/work.png"
import Stats from "../../assets/icons/stat.png"
import Navbar from "../../components/navbars/courseLandingNavbar"
import ReactGA from "react-ga"
import Curriculum from "../../components/curriculum"
import oneonone from "../../assets/images/1on1.png"
import ApplyBox from "../../components/applyBox"
import courseData from "../../utils/course-data"
import moment from "moment"
import Casey from "../../assets/images/casey.jpg"
import Jeff from "../../assets/images/jeff.jpg"
import Pierre from "../../assets/images/pierre.jpg"
import IBM from "../../assets/images/ibm.png"
import Citrix from "../../assets/images/citrix.jpg"
import Seguno from "../../assets/images/seguno.jpg"
import Marc from "../../assets/images/marc.jpg"
import Dev from "../../assets/images/dev.jpg"
import Optic from "../../assets/images/optic.jpg"
import Peter from "../../assets/images/peter.jpg"
import Praxent from "../../assets/images/praxent.png"
import Kate from "../../assets/images/kate.jpg"
import Tucker from "../../assets/images/tucker.jpg"
import Revops from "../../assets/images/revops.png"
import Edward from "../../assets/images/edward.jpg"
import Untappd from "../../assets/images/untappd.jpg"
import Klearly from "../../assets/images/klearly.png"
import Mazen from "../../assets/images/mazen.jpg"
import Capacity from "../../components/capacity"
import DepositForm from "../../components/forms/depositForm"

const CourseContainer = styled.div`
  display: block;
  height: 700px;
  background: none;
  background-image: url(${MattBig});
  background-size: cover;
  background-position: center center;
`

const HeroContainer = styled(Container)`
  padding-top: 250px;
  max-width: 1000px;
  font-family: sans-serif;

  .col-hero-text {
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--white);
    }
    .subtext {
      color: var(--white);
    }
    .cta-syllabus {
      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
`

const CurriculumSection = styled.section`
  padding-bottom: 80px;
  background-color: var(--blue);
  p {
    color: #fff;
    padding-top: 20px;
    text-align: center;
  }
  h2 {
    margin-top: 40px;
    text-align: center;
    color: var(--white);
  }
  .btn-apply-curriculum {
    margin-top: 40px;
  }
`

const FeaturesSection = styled.section`
  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }
  .features-title {
    padding-top: 40px;
    text-align: center;
  }
  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`

const DatesRow = styled(Row)`
  margin-top: 80px;
  .dates-container {
    background-color: var(--yellow);
    border: 4px solid var(--black);
    box-shadow: -15px 15px var(--black);
    h2 {
      text-align: center;
      padding-top: 20px;
    }
  }
  .dates-table-container {
    padding: 20px 50px 0px 50px;
  }
  .dates-table-row {
    margin-bottom: 25px;
    margin-top: 35px;
  }
  .dates-start {
    h5 {
      margin-bottom: 5px;
    }
    p {
      text-align: left;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 14px;
      color: var(--black);
    }
  }
  .dates-length {
    p {
      text-align: center;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 18px;
      color: var(--black);

      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-pace {
    p {
      text-align: left;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 14px;
      color: var(--black);
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-cta-container {
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-top: 25px;
    }
  }
  .dates-cta {
    background: #fff;
    border: 2px solid #000;
    padding: 10px 10px;
    display: inline-block;
    text-align: center;
    color: #000;
    -webkit-text-decoration: none;
    text-decoration: none;
    box-shadow: #000 -10px 8px 0px;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    font-size: 14px;
    min-width: 150px;
    text-align: center;
    font-family: "roboto-mono";
    margin-top: -10px;
    margin-bottom: 20px;
    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }
`

const IconBox = styled(Col)`
  margin-top: 40px;
  padding: 0;
  .icons {
    text-align: center;
    .icon-container {
      @media screen and (max-width: 767px) {
        text-align: left;
        padding: 10px 0 10px 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
    }
    .icon-caption {
      font-family: "roboto-mono";
      font-size: 1em;
      font-weight: bold;
      padding-top: 0;
      @media screen and (max-width: 1024px) {
        font-size: 0.7em;
      }
      @media screen and (max-width: 767px) {
        font-size: 0.875em;
      }
    }
    img {
      margin: 0;
      max-width: 64px;
      padding: 10px 0;
      @media screen and (max-width: 1024px) {
        max-width: 48px;
      }
      @media screen and (max-width: 767px) {
        margin-right: 20px;
      }
    }
    p {
      margin: 0 auto;
    }
    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }
  h2 {
    font-size: 50px;
    font-weight: 800;
    padding: 10px 0 20px;
    color: #252b33;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 20px;
  }
`

const CurriculumSchedule = styled.div`
  border: 4px solid var(--black);
  background: #fff;
  margin-top: 80px;
  box-shadow: -15px 15px var(--black);
`

const TuitionSection = styled.section`
  background-color: var(--black);
  padding-bottom: 60px;
  .tuition-title {
    color: var(--white);
    text-align: center;
    padding-top: 40px;
  }
  p {
    padding-top: 20px;
    text-align: center;
    color: var(--white);
  }
  .tuition-options-row {
    padding: 0 100px 0 100px;
    margin-top: 50px;

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .tuition-option {
    padding: 0 30px 0 30px;
    h3 {
      color: var(--white);
      text-align: left;
      font-weight: bold;
    }
    p {
      text-align: left;
    }
  }
  .tuition-cta {
    text-decoration: underline;
    a {
      color: var(--white);
    }
  }
`

const ApplySection = styled.section`
  background-color: var(--white);
  padding-bottom: 60px;
  .apply-title {
    color: var(--black);
    text-align: center;
    padding-top: 40px;
  }
  p {
    padding-top: 20px;
    text-align: center;
    color: var(--black);
  }
  .apply-steps-row {
    margin-top: 50px;
  }
  .apply-step {
    h3 {
      text-align: left;
      font-weight: bold;
    }
    .apply-step-title {
      font-weight: bold;
      margin-top: 10px;
      margin-bottom: 5px;
      text-align: left;
    }
    .apply-step-text {
      text-align: left;
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }
  .apply-step-cta {
    text-align: left;
    font-size: 14px;
    text-decoration: underline;
  }
`

const CurriculumHero = styled(Container)`
  .curriculum-left {
    p {
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 0px;
      padding-right: 50px;
    }
    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }
  .curriculum-right {
    padding: 0;
    max-width: 1000px;
    img {
      box-shadow: -15px 15px var(--black);
      border: 5px solid var(--black);
    }
  }
`

const TestimonySection = styled.section`
  .mentors-title {
    padding-top: 30px;
    text-align: center;
  }

  padding-bottom: 50px;

  .hired-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 40px;
  }

  .test-container {
    border: 5px solid var(--black);
    margin: 20px;
    background-color: #fff;
    box-shadow: -15px 15px var(--black);
    position: relative;
    margin-top: 150px;
  }

  .test-text {
    padding: 50px 20px 20px 20px;
    margin: 0;
    font-size: 0.875em;
  }

  .test-img-box {
    border: 5px solid var(--black);
    max-width: 100px;
    box-shadow: -10px 10px var(--black);
    position: absolute;
    top: -75px;
    left: 30px;

    @media screen and (max-width: 1200px) {
      top: -85px;
    }

    img {
      width: 100%;
      margin: 0;
    }
  }

  .test-info-container {
    padding: 20px 20px 5px 20px;
  }

  .test-name {
    text-align: center;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: bold;
  }

  .test-company {
    /* width: 100px; */
    margin-bottom: 0;
    padding-top: 5px;

    img {
      width: 100%;
      max-width: 100px;
      display: block;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 767px) {
    .test-padding-top {
      margin-top: 100px;
    }
  }
`

const Index = ({ location }) => {
  const [showSyll, setShowSyll] = useState(false)
  const [showDepo, setShowDepo] = useState(false)

  const handleCloseSyll = () => setShowSyll(false)
  const handleShowSyll = e => setShowSyll(true)

  const handleCloseDepo = () => setShowDepo(false)
  const handleShowDepo = e => setShowDepo(true)

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "waitlist",
    })
  }

  const renderNextDepositDeadline = course => {
    return courseData.returnNextDepositDate(course.deposit_deadlines)
  }

  return (
    <Layout>
      <CourseContainer>
        <Navbar location={location} />
        <PSModal
          handleClose={handleCloseSyll}
          handleShow={handleShowSyll}
          show={showSyll}
        >
          <SyllabusForm close={handleCloseSyll} />
        </PSModal>

        <PSModal
          handleClose={handleCloseDepo}
          handleShow={handleShowDepo}
          show={showDepo}
        >
          <DepositForm
            close={handleCloseDepo}
            align="center"
            price={`${process.env.GATSBY_PREP_PRICE}`}
            location={location}
          />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className="col-hero-text" md={12}>
              <h1>Become a Job-Ready Software Engineer_</h1>
              <p className="subtext">
                Only 10 spots available for each program.
              </p>
              <Button
                classToAdd="cta-syllabus"
                fireGTMEvent={fireGTMEvent}
                handleCTAClick={e => handleShowSyll(e)}
                text={"Download Syllabus"}
              />
            </Col>
          </Row>
        </HeroContainer>
      </CourseContainer>
      <FeaturesSection>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="features-title">Overview</h2>
              <p>
                Your path to becoming a Software Engineer requires more than
                just great technical skills. We've planned everything necessary
                for you to launch your new tech career.
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="background-overflow" md={12}>
              <IconBox>
                <div className="icons">
                  <Row>
                    <Col md={3}>
                      <div className="icon-container">
                        <img src={Mentor} alt=""></img>{" "}
                        <p className="icon-caption">1 on 1 Mentors</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="icon-container">
                        <img src={Monitor} alt=""></img>{" "}
                        <p className="icon-caption">Full Stack Curriculum</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="icon-container">
                        <img src={Work} alt=""></img>{" "}
                        <p className="icon-caption">A Built in Network</p>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div className="icon-container">
                        <img src={Stats} alt=""></img>{" "}
                        <p className="icon-caption">
                          A Complete Career Development Track
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </IconBox>
            </Col>
          </Row>
          <Row className="support-row">
            <CurriculumHero fluid>
              <Row>
                <Col className="curriculum-left" md={6}>
                  <h3>A 1 on 1 Mentor to Guide You</h3>
                  <p className="compare-text">
                    From day 1, you'll be matched with an expert Software
                    Engineering mentor who will walk you through every step of
                    the curriculum via regular 1 on 1 video meetings. After
                    graduation, you'll do the same with a Career Services mentor
                    until you land your first job.
                    <br></br>
                    <br></br>
                    <Button
                      fireGTMEvent={fireGTMEvent}
                      handleCTAClick={handleShowSyll}
                      text={"Download Syllabus"}
                    />
                  </p>
                </Col>
                <Col className="curriculum-right" md={6}>
                  <div className="cover">
                    <img src={oneonone} alt=""></img>
                  </div>
                </Col>
              </Row>
            </CurriculumHero>
            <DatesRow>
              <Col md={12}>
                <div className="dates-container">
                  <h2>Dates & Deadlines</h2>
                  <div className="dates-table-container">
                    {courseData.upcomingCourses.map(function (course, i) {
                      return (
                        <>
                          <Row className="dates-table-row" key={i}>
                            <Col md={3}>
                              <div className="dates-start">
                                <h5>
                                  {moment(course.startDate).format(
                                    "MMMM D, YYYY"
                                  )}
                                </h5>
                                <p>
                                  Early Deposit Deadline 1 (and $
                                  {course.deposit_deadlines[0].discount}{" "}
                                  discount):{" "}
                                  <strong>
                                    {moment(
                                      course.deposit_deadlines[0].date
                                    ).format("MM/DD/YY")}
                                  </strong>
                                </p>
                                <p>
                                  Early Deposit Deadline 2 (and $
                                  {course.deposit_deadlines[1].discount}{" "}
                                  discount):{" "}
                                  <strong>
                                    {moment(
                                      course.deposit_deadlines[1].date
                                    ).format("MM/DD/YY")}
                                  </strong>
                                </p>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="dates-length">
                                <p>{course.program}</p>
                                <p>{course.length}</p>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="dates-pace">
                                <p>
                                  Self-paced content with dedicated 1 on 1
                                  Mentor and unlimited collaboration with fellow
                                  students via Zoom and Slack.
                                </p>
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="dates-cta-container">
                                <div>
                                  <Link to="/apply">
                                    <Button
                                      link={true}
                                      classToAdd="dates-cta"
                                      text={"Apply"}
                                    />
                                  </Link>
                                </div>
                                <div>
                                  <Button
                                    classToAdd="dates-cta"
                                    fireGTMEvent={fireGTMEvent}
                                    handleCTAClick={e => handleShowDepo(e)}
                                    text={"Pay Deposit"}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          {i ===
                          courseData.upcomingCourses.length - 1 ? null : (
                            <hr></hr>
                          )}
                        </>
                      )
                    })}
                  </div>
                </div>
              </Col>
            </DatesRow>
          </Row>
        </Container>
      </FeaturesSection>
      <CurriculumSection>
        <Container>
          <Row id="curriculum">
            <Col md={12}>
              <h2>Curriculum Modules</h2>
              <p>
                Coding is a different skill than "job searching", especially
                "career changing", which is why we include both a "Software
                Enginnering" track and a "Career Development" track in our
                program's curriculum. The following modules are the same for
                both the Full-time and Part-time programs. The central princple
                of Parsity is <strong>to get you building real software</strong>
                .
              </p>
              <CurriculumSchedule>
                <Curriculum />
              </CurriculumSchedule>
              <div className="text-center btn-apply-curriculum">
                <Link to="/apply">
                  <Button link={true} classToAdd="dates-cta" text={"Apply"} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </CurriculumSection>
      <TestimonySection>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="mentors-title">1-on-1 Mentors</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Casey}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Casey Smith</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={IBM}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Jeff}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Jeffrey Altneu</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={Citrix}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Marc}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Marc Baumbach</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={Seguno}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Dev}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Dev Doshi</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={Optic}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Peter}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Peter Elbaum</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={Praxent}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Kate}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Kate Kremer</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={IBM}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Tucker}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Tucker Blackwell</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={Revops}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Edward}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Edward Barton</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={Untappd}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="test-container">
                <div className="test-img-box">
                  <img alt="" src={Mazen}></img>
                </div>
                <div className="test-info-container">
                  <Row>
                    <Col lg={7}>
                      <p className="test-name">Mazen Chami</p>
                    </Col>
                    <Col lg={5}>
                      <p className="test-company">
                        <img alt="" src={Klearly}></img>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </TestimonySection>
      <TuitionSection>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="tuition-title">Tuition Options</h1>
              <p>No gimmicks or ISA's - just one low, transpent fee.</p>
            </Col>
          </Row>
          <Row className="tuition-options-row">
            <Col md={6}>
              <div className="tuition-option">
                <h3>Pay $0 upfront</h3>
                <p>
                  Through{" "}
                  <a
                    href="https://partner.ascentfunding.com/parsity/"
                    target="_blank"
                  >
                    Ascent
                  </a>
                  , you can choose to make no payments while you study and for
                  several months after graduation.{" "}
                  <Link to="/apply">Apply</Link> to learn more.
                </p>
              </div>
            </Col>
            <Col md={6}>
              <div className="tuition-option">
                <h3>Pay $9,200 upfront</h3>
                <p>
                  One low, gimmick-free, upfront payment will cover the entirety
                  of your tuition and Career Coaching for 6 months after
                  graduation. Discounts are available for early deposits. Learn
                  more here: <Link to="/deposit">Deposit Discounts</Link>
                </p>
              </div>
            </Col>
            <Col md={12}>
              {/* <Capacity/> */}
              {courseData.upcomingCourses.map(function (course) {
                // return <Capacity course={course} page={'course'} />
              })}
              <div className="text-center btn-apply-curriculum">
                <Link to="/apply">
                  <Button link={true} classToAdd="dates-cta" text={"Apply"} />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </TuitionSection>
      <ApplySection>
        <Container>
          <Row>
            <Col md={12}>
              <h1 className="apply-title">How to Apply</h1>
            </Col>
          </Row>
          <Row className="apply-steps-row">
            <Col md={3}>
              <div className="apply-step">
                <h3>Hold Your Spot</h3>
                <p className="apply-step-title">Pay Deposit Early (Optional)</p>
                <p className="apply-step-text">
                  At any point during the admissions process, you can pay the
                  $986 deposit to hold your spot in an upcoming program. This
                  will allow you time to go through the admissions process and
                  will normally grant you a discount. If you're not accepted,
                  we'll refund your deposit.
                </p>
                <p className="apply-step-cta">
                  <Link to="/deposit">Learn More</Link>
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="apply-step">
                <h3>Step 1</h3>
                <p className="apply-step-title">Apply to Parsity</p>
                <p className="apply-step-text">
                  Simply fill out our online application and share a few details
                  about yourself and why you want to change careers. You must be
                  18 years or older to apply.
                </p>
                <p className="apply-step-cta">
                  <Link to="/apply">Apply Now</Link>
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="apply-step">
                <h3>Step 2</h3>
                <p className="apply-step-title">Complete Code Challenge</p>
                <p className="apply-step-text">
                  As soon as you fill out an application, you'll receive
                  instructions on how to take our JavaScript Admissions Code
                  Challenge. Not ready to take it? No worries, our{" "}
                  <Link to="/prep">Prep Course</Link> is a great next step for
                  you. Keep in mind, Software Engineering isn't for everyone and
                  this will take real work.
                </p>
              </div>
            </Col>
            <Col md={3}>
              <div className="apply-step">
                <h3>Step 3</h3>
                <p className="apply-step-title">Enroll Upon Acceptance</p>
                <p className="apply-step-text">
                  Upon acceptance, we'll give you a few days to pay your deposit
                  ($986) which will hold your spot until tuition is due, a few
                  weeks before the program begins. At this point, you'll receive
                  access to Prep Course materials and a questionairre which will
                  help us match you with your mentor.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </ApplySection>
      <ApplyBox text="Ready For Your New Career in Tech?" />
    </Layout>
  )
}

export default Index
